// Contact

.contact-info {
	margin: 0 auto 100px auto;
	max-width: 890px;
	padding: 60px 0 0 0;

	h2 {
    color: @gray-light;
    text-align: center;
  }
}

.contact-info-break {
  background: url(../images/break-ospc.png) top center no-repeat;
  height: 20px;
  margin: 0 auto 20px auto;
  max-width: 870px;
}

.contact-social {
	.clearfix();
	list-style: none;
	margin: 20px 0;
	padding: 0;
	@media (min-width: @screen-sm) {
		margin: 20px 80px;
  }
	li {
		line-height: 35px;
		margin: 10px 0;
		position: relative;
		text-align: center;
		@media (min-width: @screen-sm) {
	    float: left;
	    text-align: left;
			width: 33.3333%;
	  }
		a {
			display: inline-block;
		}
		a:before {
			color: #3d4544;
			float: left;
			font-size: 35px;
			height: 35px;
			margin-right: 10px;
			position: relative;
			right: 0;
			top: -1px;
			width: 35px;
		}
		&.contact-facebook a:before {
			.icon-font;
      &:extend(.icon-facebook:before);
		}
		&.contact-linkedin a:before {
			.icon-font;
      &:extend(.icon-linkedin:before);
		}
		&.contact-twitter a:before {
			.icon-font;
      &:extend(.icon-twitter:before);
		}
	}
	&.stacked {
		margin: 0 0 20px 0;
		li {
			float: none;
			text-align: left;
			width: 100%;
		}
	}
}
