// App

.app {
	.border-radius(3px);
	background-color: @app-bg;
	border-top: 2px solid white;
	margin: 0 auto;
	max-width: 870px;
	padding: 20px 50px;

	.app-logo {
		border-bottom: 1px solid white;
		padding: 20px 0;
		text-align: center;
		img {
			display: block;
			margin: 0 auto;
		}
	}
	.app-info {
		padding: 25px 10px 20px 10px;
		@media (min-width: @screen-sm) {
	    width: 50%;
	  }
	}
}

.app-taxbrain {
	background-image: url(../images/gears.png);
	background-position: 94% 90%;
	background-repeat: no-repeat;
}
