@font-face {
  font-family: 'ospc';
  src:url('../fonts/icons/ospc.eot?-bduj6b');
  src:url('../fonts/icons/ospc.eot?#iefix-bduj6b') format('embedded-opentype'),
    url('../fonts/icons/ospc.woff?-bduj6b') format('woff'),
    url('../fonts/icons/ospc.ttf?-bduj6b') format('truetype'),
    url('../fonts/icons/ospc.svg?-bduj6b#ospc') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'ospc';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-info:before {
  content: "\e612";
}

.icon-checkmark:before {
  content: "\e600";
}

.icon-facebook:before {
  content: "\e601";
}

.icon-linkedin:before {
  content: "\e602";
}

.icon-twitter:before {
  content: "\e603";
}

.icon-code-doc:before {
  content: "\e60f";
}

.icon-github:before {
  content: "\e610";
}

.icon-model-diagram:before {
  content: "\e611";
}

.icon-arrows-inward:before {
  content: "\e604";
}

.icon-invisible-folder:before {
  content: "\e605";
}

.icon-wrenches:before {
  content: "\e606";
}

.icon-gear-fill-lg:before {
  content: "\e607";
}

.icon-gear-fill-sm:before {
  content: "\e608";
}

.icon-gear-lg:before {
  content: "\e609";
}

.icon-gear-sm:before {
  content: "\e60a";
}

.icon-left-arrow:before {
  content: "\e60b";
}

.icon-magnifying-glass:before {
  content: "\e60c";
}

.icon-right-arrow:before {
  content: "\e60d";
}

.icon-taxbrain-mark:before {
  content: "\e60e";
}

.icon-info-thin:before {
  content: "\e335";
}

.icon-down-arrow-thin:before {
  content: "\e613";
}
