// About

.about-ospc {
  background-color: white;
  padding: 60px 0;
  @media (min-width: @screen-sm) {
    margin: 0 30px;
  }
}

.about-mission {
  h2 {
    color: @gray-light;
    text-align: center;
  }
  p {
    color: @lime;
  }
  @media (min-width: @screen-sm) {
    padding: 0 100px;
    text-align: center;
  }
}

.about-break {
  background: url(../images/break-about.png) top center;
  height: 3px;
  margin: 80px auto 80px auto;
  max-width: 990px;
}

.about-history {
  margin-bottom: 60px;
  h2 {
    text-align: center;
  }
  p {
    color: @gray-light;
  }
  @media (min-width: @screen-sm) {
    padding: 0 100px;
  }
}

.about-products {
  h2 {
    color: @gray-light;
    text-align: center;
  }
  p {
    color: @gray-light;
  }
  @media (min-width: @screen-sm) {
    padding: 0 70px;
  }

  .product {
    .clearfix();
    background-color: @app-bg;
    border-radius: 4px;
    .product-logo {
      border-right: 0;
      float: left;
      margin: 13px 0;
      padding: 25px 35px 25px 45px;
      @media (min-width: @screen-md) {
        border-right: 1px solid @gray;
        padding: 25px 35px 25px 30px;
      }
      img {
        width: 112px;
      }
    }
    .product-info {
      float: left;
      margin: 0 50px 30px 30px;
      @media (min-width: @screen-md) {
        margin: 34px 20px 30px 30px;
      }
      span {
        font-size: 1.2142em;
      }
      a {
        font-size: .9286em;
        font-weight: 700;
      }
    }
  }
}

.sponsor-info {
  padding-top: 50px;
  text-align: center;
  h2 {
    color: @gray-light;
    text-align: center;
  }
  div {
    background-color: white;
    padding: 40px 15px;
    img {
      margin: 0 auto 20px auto;
    }
    p {
      margin: 0 auto 0.5em auto;
      max-width: 800px;
      font-family: @font-verb-cond;
      font-size: 17px;
      line-height: 26px;

      &:first-child {
        
        font-size: 18px;
        line-height: 26px;
      }
    }
  }
}
