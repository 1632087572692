// News detail

.news-detail-break {
  background: url(../images/break-ospc.png) top center no-repeat;
  height: 20px;
  margin: 100px 0 60px 0;
  max-width: 1500px;
}

.news-detail-header {
  margin: 20px 0 40px 0;
  h1 {
    font-family: @font-verb-cond;
    font-size: 41px;
    line-height: 51px;
  }
}
.news-detail {
  color: @gray-light;
  font-size: 21px;
  line-height: 32px;
  padding-right: 50px;

  h2, h3 {
    color: @gray-dark;
    margin: 20px 0;
  }
  p {
    font-family: @font-verb-cond;
    margin-bottom: 1em;
    &.intro {
      font-family: @font-verb;
    }
  }
}

.news-detail-sidebar {
  color: @gray-light;

  h2, h3 {
    color: @lime;
  }
}

.figure {
  margin-bottom: 30px;
  img {
    display: block;
    max-width: 100%;
  }
  .caption {
    border-top: 4px solid #28c0d5;
    color: @gray-light;
    font-size: 13px;
    padding: 10px;
  }
}

.ordered-list {
  // list-style: none;
  margin: 0 0 2em 30px;
  padding: 0;
  li {
    color: @gray-dark;
    margin-bottom: 15px;
  }
}

.unordered-list {
  list-style: none;
  margin: 0 0 2em 0;
  padding: 0;

  li {
    color: @gray-dark;
    margin-bottom: 15px;
    padding: 0 0 0 30px;
    position: relative;
    &:before {
      .icon-font;
      &:extend(.icon-right-arrow:before);

      color: @lime;
      left: 0;
      position: absolute;
      top: 1px;
    }
  }
}
