// News items

.news-items {
  background-color: @section-bg;
  padding: 12px 0 30px 0;
  margin-bottom: 75px;

  .see-all {
    // color: @gray-dark;
    &:after {
      .icon-font;
      &:extend(.icon-right-arrow:before);
      color: @gray-light;
      padding-left: 3px;
      position: relative;
      top: 1px;
    }
    &:hover {
      color: #000;
      &:after {
        color: #000;
      }
    }
  }
}

.news-item {
	background-color: white;
	border-bottom: 3px solid #dce1e1;
	margin-bottom: 30px;
  min-height: 210px;
	padding: 20px 50px 20px 20px;
  position: relative;

	h3, h4 {
    color: #28c0d5;
    font-family: @font-verb-cond;
		font-size: 26px;
		font-weight: 400;
		line-height: 32px;
	}

  &:after {
    .icon-font;
    &:extend(.icon-right-arrow:before);
    color: #ebefef;
    font-size: 32px;
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
  &:hover {
    &:after {
      color: @gray;
    }
  }

  &.tagged {
    // page corner
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      border-width: 0 32px 32px 0;
      border-style: solid;
      border-color: #28c0d5 @section-bg;
    }
    // alt color
    a {
      color: #28c0d5;
      &:hover {
        color: #000;
      }
    }
  }
}

.news-items-dark {
  background-color: @gray-light;
  .news-item {
    &:before {
      border-color: #28c0d5 @gray-light;
    }
  }
  .see-all {
    color: white;
    &:after {
      color: white;
    }
  }
}

.news-items-trans {
  background-color: @site-bg;
  .news-item {
    &:before {
      border-color: #28c0d5 @site-bg;
    }
  }
}
