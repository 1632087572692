.docs-links {
    border-right: 2px solid #eff4f4;
}

.docs-header {
    font-size: 32px;
    font-family: 'MuseoSansRounded-700', Arial, sans-serif;
}

.docs-section {
    border-bottom: 2px solid #eff4f4;
    margin-bottom: 50px;
    h1 {
        font-family: 'MuseoSansRounded-700', Arial, sans-serif;
        border-bottom: 2px solid #eff4f4;
        color: #00968b;
        font-size: 32px;
        margin: 0 0 10px 0;
        padding-bottom: 20px;
    }
    h2 {
        font-family: 'MuseoSansRounded-700', Arial, sans-serif;
        font-size: 21px;
        letter-spacing: 0;
        margin: 20px 0 20px 0;
        text-transform: none;
    }
    .docs-video {
        margin-bottom: 20px;
    }
}
