// Contribute

.contribute {
	margin-bottom: 100px;

	h2 {
		.section-header;
	}
}

.contribute-block {
	.border-radius(4px);
	background-color: white;
	border: 2px solid @section-bg;
	font-size: 0.9286em;
	padding: 15px;
	text-align: center;

	.icon {
		color: @gray-light;
		display: block;
		font-size: 50px;
		margin: 10px 0 20px 0;
	}
	hr {
		border-top: 1px solid @section-bg;
	}
	div[class$="blurb"] {
		opacity: 0.7;
	}
}
