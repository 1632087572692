// Buttons

.btn {
  padding: 19px 50px;
  border-radius: 4px;
  color: white;
  font-size: 17px;
  font-family: @font-default;
  font-weight: 400;
  line-height: 1;
  text-align: center;

  &:focus, &:active, &:hover {
    outline: none;
  }

  // small
  &.btn-sm {
    padding: 8px 15px;
  }
  // block
  &.btn-block {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.btn-default {
  color: black;
}

// primary
.btn-primary {
  background-color: #047099;
  border: 0;
  border-bottom: 2px solid #046589;

  &:hover, &:focus {
    border-color: #046589;
    background-color: #046589;
    color: white;
  }

  &:active, &.active {
    border-color: #046589;
    background-color: #046589;
    .box-shadow(inset 0 1px 2px rgba(0,0,0,.5));
  }

  &.disabled, &[disabled], fieldset[disabled] & {
    .opacity(0.3);
  }
}

// secondary
.btn-secondary {
  background-color: #00968b;
  border: 0;
  border-bottom: 2px solid #00877d;

  &:hover, &:focus {
    border-color: #00877d;
    background-color: #00877d;
    color: white;
  }

  &:active, &.active {
    border-color: #00877d;
    background-color: #00877d;
    .box-shadow(inset 0 1px 2px rgba(0,0,0,.5));
  }

  &.disabled, &[disabled], fieldset[disabled] & {
    .opacity(0.3);
  }
}

// tertiary
.btn-tertiary {
  background-color: #2ca2cd;
  border: 0;
  border-bottom: 2px solid #00877d;

  &:hover, &:focus {
    border-color: #00877d;
    background-color: #00877d;
    color: white;
  }

  &:active, &.active {
    border-color: #00877d;
    background-color: #00877d;
    .box-shadow(inset 0 1px 2px rgba(0,0,0,.5));
  }

  &.disabled, &[disabled], fieldset[disabled] & {
    .opacity(0.3);
  }
}



// okay
.btn-okay {
  background-color: #25d5c8;
  border: 0;
  border-bottom: 2px solid #21bfb4;

  &:hover, &:focus {
    border-color: #21bfb4;
    background-color: #21bfb4;
    color: white;
  }

  &:active, &.active {
    border-color: #21bfb4;
    background-color: #21bfb4;
    .box-shadow(inset 0 1px 2px rgba(0,0,0,.5));
  }

  &.disabled, &[disabled], fieldset[disabled] & {
    .opacity(0.3);
  }
}

// explore
.btn-explore {
  background-color: #2ca8d7;
  border: 0;
  border-bottom: 2px solid #2897c1;

  &:hover, &:focus {
    border-color: #2897c1;
    background-color: #2897c1;
    color: white;
  }

  &:active, &.active {
    border-color: #2897c1;
    background-color: #2897c1;
    .box-shadow(inset 0 1px 2px rgba(0,0,0,.5));
  }

  &.disabled, &[disabled], fieldset[disabled] & {
    .opacity(0.3);
  }
}

// text
.btn-text {
  background-color: transparent;
  border: 0;
  border-bottom: 0px solid transparent;
  color: #2ca8d7;

  &:hover, &:focus {
    border-color: #eff4f4;
    background-color: #eff4f4;
    color: #047099;
  }

  &:active, &.active {
    border-color: #2ca8d7;
    background-color: #2ca8d7;
    color: white;
    .box-shadow(none);
  }

  &.disabled, &[disabled], fieldset[disabled] & {
    .opacity(0.3);
  }
}

// text
.btn-share {
  background-color: transparent;
  border: 0;
  border-bottom: 0px solid transparent;
  color: #ff912c;

  &:hover, &:focus {
    border-color: #eff4f4;
    background-color: #eff4f4;
    color: #047099;
  }

  &:active, &.active {
    border-color: #2ca8d7;
    background-color: #2ca8d7;
    color: white;
    .box-shadow(none);
  }

  &.disabled, &[disabled], fieldset[disabled] & {
    .opacity(0.3);
  }
}

// cpi
// very specific to override default form-group label formatting
.form-group.cpi-group .btn.btn-cpi {
  background-color: transparent;
  border: 0;
  border-bottom: 0px solid transparent;
  color: #95d3eb;
  font-size: 11px;
  font-weight: 700;
  font-family: @font-default;
  line-height: 1;
  padding: 5px 4px 2px 4px;
  position: relative;
  text-transform: uppercase;
  top: -1px;

  &:hover, &:focus, &.focus {
    border-color: #eff4f4;
    background-color: #eff4f4;
    color: #047099;
    outline: none;
  }

  &:active, &.active {
    .box-shadow(none);
    border-color: #00968b;
    background-color: #00968b;
    color: white;
    padding-right: 15px;

    &:after {
      .icon-font;
       &:extend(.icon-checkmark:before);
       font-size: 8px;
       position: absolute;
       right: 4px;
       top: 5px;
    }
  }

  &.disabled, &[disabled], fieldset[disabled] & {
    .opacity(0.5);
    border-color: #eff4f4;
    background-color: #eff4f4;
    color: #869191;
    outline: none;
  }
}
