section#media-coverage {
  background-color: #ffffff;
  margin-bottom: 75px;
  div[class^="block-grid"] {
    div {
      display: table;
      height: 200px;
      div {
        display: table-cell;
        vertical-align: middle;
        img {
          width: 100%;
        }
      }
    }
  }
}
