// Home

// override navbar color
.wrapper-home {
  .navbar-ospc {
    background-color: @site-bg;
  }
}

.home-display {
  .background-image(linear-gradient(to bottom, #fafcfc 0%,#bdc9c9 100%));
  .clearfix();

  .home-display-pattern {
    // background: url(../images/bg-pattern.png) 50% 50% no-repeat;
  }
  .home-display-app {
    margin-bottom: 50px;
    h2 {
      .section-header;
      color: white;
    }
  }
}

.home-hero {
  .background-image(linear-gradient(to bottom, #fafcfc 0%,#ffffff 100%));
  // background-color: white;
  margin: 0 auto 40px auto;
  max-width: 980px;
  padding: 0 30px 30px 30px;
  text-align: center;

  .home-hero-break {
    background: url(../images/break-home.png) top center no-repeat;
    height: 53px;
    margin: 0 -30px 30px -30px;
    max-width: 990px;
  }
  .lead {
    color: @gray-dark;
    font-family: @font-museo-rounded;
    font-size: 32px;
    font-weight: 400;
    line-height: 50px;
    margin: 30px 20px 50px 20px;
    text-transform: uppercase;
    @media (min-width: @screen-md) {
      margin: 50px 80px 70px 80px;
    }
  }
  .home-hero-item {
    margin-bottom: 30px;
    .icon {
      color: #ebefef;
      font-size: 50px;
      &.icon-invisible-folder {
        color: #d7dcdc;
      }
    }
  }
  .h3 {
    color: @lime;
    @media (min-width: @screen-md) {
      span {
        display: block;
      }
    }
  }
}

@footer-height: 249px;

#content, .wrapper, body, html {
  min-height: 100%;
}

body, html {
  height: 100%
}

#content {
  margin: 0 auto (-@footer-height);
}

footer, .push {
  min-height: @footer-height; /* '.push' must be the same height as 'footer' */
}
