/* BLOCKGRID */
[class*="block-grid-"] {
  display: block;
  margin: -15px;
  padding: 0 .9375rem;
}

[class*="block-grid-"]:before,
[class*="block-grid-"]:after {
  content: " ";
  display: table;
}

[class*="block-grid-"]:after {
  clear: both;
}

[class*="block-grid-"] > * {
  display: inline;
  padding: 15px;
  height: auto;
  float: left;
  list-style: none;
}

.block-grid-xs-1 > * {
  width: 100%;
}

.block-grid-xs-1 > *:nth-of-type(n) {
  clear: none;
}

.block-grid-xs-1 > *:nth-of-type(1n+1) {
  clear: both;
}

.block-grid-xs-2 > * {
  width: 50%;
}

.block-grid-xs-2 > *:nth-of-type(n) {
  clear: none;
}

.block-grid-xs-2 > *:nth-of-type(2n+1) {
  clear: both;
}

.block-grid-xs-3 > * {
  width: 33.333333333333336%;
}

.block-grid-xs-3 > *:nth-of-type(n) {
  clear: none;
}

.block-grid-xs-3 > *:nth-of-type(3n+1) {
  clear: both;
}

.block-grid-xs-4 > * {
  width: 25%;
}

.block-grid-xs-4 > *:nth-of-type(n) {
  clear: none;
}

.block-grid-xs-4 > *:nth-of-type(4n+1) {
  clear: both;
}

.block-grid-xs-5 > * {
  width: 20%;
}

.block-grid-xs-5 > *:nth-of-type(n) {
  clear: none;
}

.block-grid-xs-5 > *:nth-of-type(5n+1) {
  clear: both;
}

.block-grid-xs-6 > * {
  width: 16.666666666666668%;
}

.block-grid-xs-6 > *:nth-of-type(n) {
  clear: none;
}

.block-grid-xs-6 > *:nth-of-type(6n+1) {
  clear: both;
}

.block-grid-xs-7 > * {
  width: 14.285714285714286%;
}

.block-grid-xs-7 > *:nth-of-type(n) {
  clear: none;
}

.block-grid-xs-7 > *:nth-of-type(7n+1) {
  clear: both;
}

.block-grid-xs-8 > * {
  width: 12.5%;
}

.block-grid-xs-8 > *:nth-of-type(n) {
  clear: none;
}

.block-grid-xs-8 > *:nth-of-type(8n+1) {
  clear: both;
}

.block-grid-xs-9 > * {
  width: 11.11111111111111%;
}

.block-grid-xs-9 > *:nth-of-type(n) {
  clear: none;
}

.block-grid-xs-9 > *:nth-of-type(9n+1) {
  clear: both;
}

.block-grid-xs-10 > * {
  width: 10%;
}

.block-grid-xs-10 > *:nth-of-type(n) {
  clear: none;
}

.block-grid-xs-10 > *:nth-of-type(10n+1) {
  clear: both;
}

.block-grid-xs-11 > * {
  width: 9.090909090909092%;
}

.block-grid-xs-11 > *:nth-of-type(n) {
  clear: none;
}

.block-grid-xs-11 > *:nth-of-type(11n+1) {
  clear: both;
}

.block-grid-xs-12 > * {
  width: 8.333333333333334%;
}

.block-grid-xs-12 > *:nth-of-type(n) {
  clear: none;
}

.block-grid-xs-12 > *:nth-of-type(12n+1) {
  clear: both;
}

@media (min-width: 768px) {
  .block-grid-sm-1 > * {
    width: 100%;
  }

  .block-grid-sm-1 > *:nth-of-type(n) {
    clear: none;
  }

  .block-grid-sm-1 > *:nth-of-type(1n+1) {
    clear: both;
  }

  .block-grid-sm-2 > * {
    width: 50%;
  }

  .block-grid-sm-2 > *:nth-of-type(n) {
    clear: none;
  }

  .block-grid-sm-2 > *:nth-of-type(2n+1) {
    clear: both;
  }

  .block-grid-sm-3 > * {
    width: 33.333333333333336%;
  }

  .block-grid-sm-3 > *:nth-of-type(n) {
    clear: none;
  }

  .block-grid-sm-3 > *:nth-of-type(3n+1) {
    clear: both;
  }

  .block-grid-sm-4 > * {
    width: 25%;
  }

  .block-grid-sm-4 > *:nth-of-type(n) {
    clear: none;
  }

  .block-grid-sm-4 > *:nth-of-type(4n+1) {
    clear: both;
  }

  .block-grid-sm-5 > * {
    width: 20%;
  }

  .block-grid-sm-5 > *:nth-of-type(n) {
    clear: none;
  }

  .block-grid-sm-5 > *:nth-of-type(5n+1) {
    clear: both;
  }

  .block-grid-sm-6 > * {
    width: 16.666666666666668%;
  }

  .block-grid-sm-6 > *:nth-of-type(n) {
    clear: none;
  }

  .block-grid-sm-6 > *:nth-of-type(6n+1) {
    clear: both;
  }

  .block-grid-sm-7 > * {
    width: 14.285714285714286%;
  }

  .block-grid-sm-7 > *:nth-of-type(n) {
    clear: none;
  }

  .block-grid-sm-7 > *:nth-of-type(7n+1) {
    clear: both;
  }

  .block-grid-sm-8 > * {
    width: 12.5%;
  }

  .block-grid-sm-8 > *:nth-of-type(n) {
    clear: none;
  }

  .block-grid-sm-8 > *:nth-of-type(8n+1) {
    clear: both;
  }

  .block-grid-sm-9 > * {
    width: 11.11111111111111%;
  }

  .block-grid-sm-9 > *:nth-of-type(n) {
    clear: none;
  }

  .block-grid-sm-9 > *:nth-of-type(9n+1) {
    clear: both;
  }

  .block-grid-sm-10 > * {
    width: 10%;
  }

  .block-grid-sm-10 > *:nth-of-type(n) {
    clear: none;
  }

  .block-grid-sm-10 > *:nth-of-type(10n+1) {
    clear: both;
  }

  .block-grid-sm-11 > * {
    width: 9.090909090909092%;
  }

  .block-grid-sm-11 > *:nth-of-type(n) {
    clear: none;
  }

  .block-grid-sm-11 > *:nth-of-type(11n+1) {
    clear: both;
  }

  .block-grid-sm-12 > * {
    width: 8.333333333333334%;
  }

  .block-grid-sm-12 > *:nth-of-type(n) {
    clear: none;
  }

  .block-grid-sm-12 > *:nth-of-type(12n+1) {
    clear: both;
  }
}
@media (min-width: 992px) {
  .block-grid-md-1 > * {
    width: 100%;
  }

  .block-grid-md-1 > *:nth-of-type(n) {
    clear: none;
  }

  .block-grid-md-1 > *:nth-of-type(1n+1) {
    clear: both;
  }

  .block-grid-md-2 > * {
    width: 50%;
  }

  .block-grid-md-2 > *:nth-of-type(n) {
    clear: none;
  }

  .block-grid-md-2 > *:nth-of-type(2n+1) {
    clear: both;
  }

  .block-grid-md-3 > * {
    width: 33.333333333333336%;
  }

  .block-grid-md-3 > *:nth-of-type(n) {
    clear: none;
  }

  .block-grid-md-3 > *:nth-of-type(3n+1) {
    clear: both;
  }

  .block-grid-md-4 > * {
    width: 25%;
  }

  .block-grid-md-4 > *:nth-of-type(n) {
    clear: none;
  }

  .block-grid-md-4 > *:nth-of-type(4n+1) {
    clear: both;
  }

  .block-grid-md-5 > * {
    width: 20%;
  }

  .block-grid-md-5 > *:nth-of-type(n) {
    clear: none;
  }

  .block-grid-md-5 > *:nth-of-type(5n+1) {
    clear: both;
  }

  .block-grid-md-6 > * {
    width: 16.666666666666668%;
  }

  .block-grid-md-6 > *:nth-of-type(n) {
    clear: none;
  }

  .block-grid-md-6 > *:nth-of-type(6n+1) {
    clear: both;
  }

  .block-grid-md-7 > * {
    width: 14.285714285714286%;
  }

  .block-grid-md-7 > *:nth-of-type(n) {
    clear: none;
  }

  .block-grid-md-7 > *:nth-of-type(7n+1) {
    clear: both;
  }

  .block-grid-md-8 > * {
    width: 12.5%;
  }

  .block-grid-md-8 > *:nth-of-type(n) {
    clear: none;
  }

  .block-grid-md-8 > *:nth-of-type(8n+1) {
    clear: both;
  }

  .block-grid-md-9 > * {
    width: 11.11111111111111%;
  }

  .block-grid-md-9 > *:nth-of-type(n) {
    clear: none;
  }

  .block-grid-md-9 > *:nth-of-type(9n+1) {
    clear: both;
  }

  .block-grid-md-10 > * {
    width: 10%;
  }

  .block-grid-md-10 > *:nth-of-type(n) {
    clear: none;
  }

  .block-grid-md-10 > *:nth-of-type(10n+1) {
    clear: both;
  }

  .block-grid-md-11 > * {
    width: 9.090909090909092%;
  }

  .block-grid-md-11 > *:nth-of-type(n) {
    clear: none;
  }

  .block-grid-md-11 > *:nth-of-type(11n+1) {
    clear: both;
  }

  .block-grid-md-12 > * {
    width: 8.333333333333334%;
  }

  .block-grid-md-12 > *:nth-of-type(n) {
    clear: none;
  }

  .block-grid-md-12 > *:nth-of-type(12n+1) {
    clear: both;
  }
}
@media (min-width: 1200px) {
  .block-grid-lg-1 > * {
    width: 100%;
  }

  .block-grid-lg-1 > *:nth-of-type(n) {
    clear: none;
  }

  .block-grid-lg-1 > *:nth-of-type(1n+1) {
    clear: both;
  }

  .block-grid-lg-2 > * {
    width: 50%;
  }

  .block-grid-lg-2 > *:nth-of-type(n) {
    clear: none;
  }

  .block-grid-lg-2 > *:nth-of-type(2n+1) {
    clear: both;
  }

  .block-grid-lg-3 > * {
    width: 33.333333333333336%;
  }

  .block-grid-lg-3 > *:nth-of-type(n) {
    clear: none;
  }

  .block-grid-lg-3 > *:nth-of-type(3n+1) {
    clear: both;
  }

  .block-grid-lg-4 > * {
    width: 25%;
  }

  .block-grid-lg-4 > *:nth-of-type(n) {
    clear: none;
  }

  .block-grid-lg-4 > *:nth-of-type(4n+1) {
    clear: both;
  }

  .block-grid-lg-5 > * {
    width: 20%;
  }

  .block-grid-lg-5 > *:nth-of-type(n) {
    clear: none;
  }

  .block-grid-lg-5 > *:nth-of-type(5n+1) {
    clear: both;
  }

  .block-grid-lg-6 > * {
    width: 16.666666666666668%;
  }

  .block-grid-lg-6 > *:nth-of-type(n) {
    clear: none;
  }

  .block-grid-lg-6 > *:nth-of-type(6n+1) {
    clear: both;
  }

  .block-grid-lg-7 > * {
    width: 14.285714285714286%;
  }

  .block-grid-lg-7 > *:nth-of-type(n) {
    clear: none;
  }

  .block-grid-lg-7 > *:nth-of-type(7n+1) {
    clear: both;
  }

  .block-grid-lg-8 > * {
    width: 12.5%;
  }

  .block-grid-lg-8 > *:nth-of-type(n) {
    clear: none;
  }

  .block-grid-lg-8 > *:nth-of-type(8n+1) {
    clear: both;
  }

  .block-grid-lg-9 > * {
    width: 11.11111111111111%;
  }

  .block-grid-lg-9 > *:nth-of-type(n) {
    clear: none;
  }

  .block-grid-lg-9 > *:nth-of-type(9n+1) {
    clear: both;
  }

  .block-grid-lg-10 > * {
    width: 10%;
  }

  .block-grid-lg-10 > *:nth-of-type(n) {
    clear: none;
  }

  .block-grid-lg-10 > *:nth-of-type(10n+1) {
    clear: both;
  }

  .block-grid-lg-11 > * {
    width: 9.090909090909092%;
  }

  .block-grid-lg-11 > *:nth-of-type(n) {
    clear: none;
  }

  .block-grid-lg-11 > *:nth-of-type(11n+1) {
    clear: both;
  }

  .block-grid-lg-12 > * {
    width: 8.333333333333334%;
  }

  .block-grid-lg-12 > *:nth-of-type(n) {
    clear: none;
  }

  .block-grid-lg-12 > *:nth-of-type(12n+1) {
    clear: both;
  }
}
